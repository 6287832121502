import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex
	position: relative
	justify-content: center
	width: 100%
	height: 50%
	overflow: hidden
    
    & span {
        font-size: $font-size
		font-family: $font-family
		font-weight: $font-width
		color: $font-color
    }
`;

const StaticCard = ({ position, digit }) => {
  return (
    <Container className={position}>
      <span>{digit}</span>
    </Container>
  );
};

export default StaticCard;
