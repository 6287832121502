import React from "react";
import FlipUnitContainer from "./FlipUnitContainer";

function msToTime(s) {
  // Pad to 2 or 3 digits, default is 2
  var pad = (n, z = 2) => ("00" + n).slice(-z);
  return (
    pad((s / 3.6e6) | 0) +
    ":" +
    pad(((s % 3.6e6) / 6e4) | 0) +
    ":" +
    pad(((s % 6e4) / 1000) | 0)
  );
}

class FlipClock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialTime: new Date(),
      chosenTime: 0,
      hours: 0,
      hoursShuffle: true,
      minutes: 0,
      minutesShuffle: true,
      seconds: 0,
      secondsShuffle: true
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.updateTime(), 50);
    let chosenTime = new Date();
    let timeEls = this.props.time.split(":");
    chosenTime.setHours(timeEls[0]);
    chosenTime.setMinutes(timeEls[1]);
    chosenTime.setSeconds(0);
    this.setState({ chosenTime });

    if (chosenTime - this.state.initialTime <= 0) {
      this.props.callback();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  updateTime() {
    let currentDate = new Date();
    let timeEls = msToTime(this.state.chosenTime - currentDate).split(":");

    let hours = timeEls[0];
    let minutes = timeEls[1];
    let seconds = timeEls[2];

    let percentage =
      ((currentDate - this.state.initialTime) /
        (this.state.chosenTime - this.state.initialTime)) *
      100;

    // this.props.updateBeerLevel(parseInt(percentage));

    if (hours !== this.state.hours) {
      // on hour chanage, update hours and shuffle state
      const hoursShuffle = !this.state.hoursShuffle;
      this.setState({
        hours,
        hoursShuffle
      });
    }
    // on minute chanage, update minutes and shuffle state
    if (minutes !== this.state.minutes) {
      const minutesShuffle = !this.state.minutesShuffle;
      this.setState({
        minutes,
        minutesShuffle
      });
    }
    // on second chanage, update seconds and shuffle state
    if (seconds !== this.state.seconds) {
      const secondsShuffle = !this.state.secondsShuffle;
      this.setState({
        seconds,
        secondsShuffle
      });
    }
    if (hours <= 0 && minutes <= 0 && seconds <= 0) {
      clearInterval(this.timerID);
      this.props.callback();
    }
  }

  render() {
    // state object destructuring
    const {
      hours,
      minutes,
      seconds,
      hoursShuffle,
      minutesShuffle,
      secondsShuffle
    } = this.state;

    return (
      <div className={"flipClock"}>
        <FlipUnitContainer
          unit={"hours"}
          digit={hours}
          shuffle={hoursShuffle}
        />
        <FlipUnitContainer
          unit={"minutes"}
          digit={minutes}
          shuffle={minutesShuffle}
        />
        <FlipUnitContainer
          unit={"seconds"}
          digit={seconds}
          shuffle={secondsShuffle}
        />
      </div>
    );
  }
}

export default FlipClock;
