import React, { Component } from "react";
import styled from "styled-components";
import posed, { PoseGroup } from "react-pose";
import ImagePicker from "../ImagePicker/ImagePicker.jsx";

const imageIds = [1, 2, 5, 6, 7, 8, 9, 10, 11];

const lgBreak = "970px";

const Container = styled.div`
  text-align: center;

  input[type="time"] {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    font-size: 2rem;
    padding: 10px;
    font-family: "Poppins";
    color: #666;

    @media (max-width: ${lgBreak}) {
      font-size: 1.5rem;
    }
  }
`;

const btnPosedProps = {
  hoverable: true,
  init: {
    scale: 1
  },
  hover: {
    scale: 1.02
  }
};

const Button = styled(posed.button(btnPosedProps))`
  font-size: 2rem;
  color: #444;
  border: none;
  cursor: pointer;
  font-weight: 900;
  margin: 20px;
  background: white;
  box-shadow: 0px 3px 6px 2px #00000047;
  border-radius: 3px;
  padding: 0px 30px;
  font-family: "Poppins";

  @media (max-width: ${lgBreak}) {
    font-size: 1.5rem;
  }
`;

const Heading = styled.h3`
  font-family: "Pacifico", cursive;
  font-size: 2rem;
  font-weight: 300;

  @media (max-width: ${lgBreak}) {
    font-size: 1.5rem;
    // width: 90%;
  }
`;

class Settings extends Component {
  state = {
    time: "",
    images: {},
    formValid: false
  };

  componentWillMount() {
    let imgObj = {};
    imageIds.forEach(id => {
      imgObj[id] = false;
    });

    if (this.existingSettingsPresent()) {
      let time = localStorage.getItem("boc-data-time");
      let images = JSON.parse(localStorage.getItem("boc-data-images"));
      images.forEach(el => {
        imgObj[el] = true;
      });
      this.setState({ time });
    }
    this.setState({ images: imgObj });
  }

  existingSettingsPresent = () => {
    if (
      localStorage.getItem("boc-data-images") ||
      localStorage.getItem("boc-data-time")
    ) {
      return true;
    }
    return false;
  };

  toggleSelectedImage = imgNo => {
    let stateImages = { ...this.state.images };
    stateImages[imgNo] = !this.state.images[imgNo];
    if (this.inputIsValid()) {
      this.setState({ images: stateImages, formValid: true });
    } else {
      this.setState({ images: stateImages, formValid: false });
    }
  };

  handleInputChange = event => {
    if (this.inputIsValid()) {
      this.setState({ time: event.target.value, formValid: true });
    } else {
      this.setState({ time: event.target.value, formValid: false });
    }
  };

  handleSubmit = () => {
    localStorage.setItem("boc-data-time", this.state.time);
    localStorage.setItem(
      "boc-data-images",
      JSON.stringify(this.createBackgroundImageArray())
    );

    if (this.inputIsValid()) {
      this.props.toggleActive();
    }
  };

  createBackgroundImageArray = () => {
    let arr = [];
    for (let key in this.state.images) {
      if (this.state.images[key] == true) {
        arr.push(key);
      }
    }
    return arr;
  };

  inputIsValid = () => {
    if (
      this.state.time.length < 1 ||
      Object.values(this.state.images).indexOf(true) == -1
    ) {
      return false;
    }
    return true;
  };

  render() {
    return (
      <Container>
        <Heading>What time is Beer o'Clock?</Heading>
        <input
          type="time"
          onChange={this.handleInputChange}
          value={this.state.time}
        />
        <Heading>Please Select Your Background Images</Heading>
        <ImagePicker
          images={imageIds}
          activeImages={this.state.images}
          toggleSelectedImage={this.toggleSelectedImage}
        />
        {this.inputIsValid() ? (
          <Button onClick={this.handleSubmit}>Let's Go</Button>
        ) : null}
      </Container>
    );
  }
}

export default Settings;
