import React, { Component } from "react";
import styled from "styled-components";
import posed, { PoseGroup } from "react-pose";
import ImageItem from "./ImageItem.jsx";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  flex-wrap: wrap;
`;

class ImagePicker extends Component {
  render() {
    return (
      <Container>
        {this.props.images.map(id => (
          <ImageItem
            imgNo={id}
            key={id}
            active={this.props.activeImages[id]}
            toggleSelectedImage={this.props.toggleSelectedImage}
          />
        ))}
      </Container>
    );
  }
}

export default ImagePicker;
