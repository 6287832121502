import React, { Component } from "react";
import styled from "styled-components";
import posed, { PoseGroup } from "react-pose";
import Settings from "./settings/Settings";
import Slider from "./slider/Slider";
import FlipClock from "./flip-clock/FlipClock.jsx";

const containerSidePadding = "padding: 0 7vw;";

const lgBreak = "970px";

const smBreak = "570px";

const Container = styled.div`
  background: grey;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

// ******** TOP ************

const Top = styled.div`
  flex-grow: 4;
  display: flex;
  padding: 0 40px;
  flex-direction: column;
  justify-content: space-evenly;
  ${containerSidePadding}
  position: relative;
  overflow: hidden;
  background: linear-gradient(#00000078, #000000b3), url(./assets/beer.jpg);
`;
const topTitlePosedProps = {
  left: { x: 0 },
  center: { x: "1vw" }
};

const TopContent = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
`;

const TopTitle = styled(posed.h2(topTitlePosedProps))`
  font-size: 9rem;
  margin: 0;
  color: white;
  font-weight: 900;
  letter-spacing: -6px;
  line-height: 8rem;
  font-family: 'Poppins', sans-serif;
  ${props => (props.beerTime ? "text-align: center;" : "text-align: left;")}
  
  
  @media (max-width: ${lgBreak}){
    font-size: 6rem;
    line-height: 5rem;
  }
  @media (max-width: ${smBreak}){
    font-size: 4rem;
    line-height: 3rem;
    letter-spacing: -3px;
  }
`;

const TopSubTitle = styled.h3`
  color: white;
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
  font-family: "Pacifico", cursive;

  @media (max-width: ${lgBreak}) {
    font-size: 1.5rem;
  }
  @media (max-width: ${smBreak}) {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
`;

// ****** BOTTOM ***********

const bottomPosedProps = {
  enter: { opacity: 1, duration: 900 },
  exit: { opacity: 0, duration: 900 }
};

const Bottom = styled(posed.div(bottomPosedProps))`
  // flex-grow: 1;
  background: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transform: none;
  position: relative;
  ${containerSidePadding}
  ${props =>
    props.active
      ? `
    background: linear-gradient(#000000b5, #000000b5), url('./assets/beer.jpg');
  `
      : `
    background: white;
  `}  
  background-size: cover;
  color: white;
`;

const BottomTitle = styled.h3`
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #272727;
  padding: 20px 0;

  ${props =>
    props.active
      ? `
    font-size: 3rem;
    margin: 20px 0;
    text-align: center;
    color: white;
    @media (max-width: ${lgBreak}){
      font-size: 2rem;
    }
  `
      : `
    font-size: 2rem;
    @media (max-width: ${lgBreak}){
      font-size: 1.5rem;
      width: 90%;
    }
    @media (max-width: ${smBreak}){
      font-size: 1.2rem;
    }
  `}
`;

const btnPosedProps = {
  pressable: true,
  hoverable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
  hover: {
    scale: 1.1
  }
};

const SettingsButton = styled(posed.div(btnPosedProps))`
  background-image: url("./assets/icons/gear-dark.svg");
  background-size: cover;
  padding: 15px;
  height: 10px;
  width: 10px;
  position: absolute;
  right: 40px;
  cursor: pointer;
`;

const SubHeadingContainer = styled(posed.div(topTitlePosedProps))`
display: flex;
align-items: flex-end;
padding: 40px 0;


${props => (props.beerTime ? "justify-content: center" : "")}
@media(max-width: ${lgBreak}){
  flex-direction: column;
  align-items: flex-start;
  ${props =>
    props.beerTime ? "align-items: center" : "align-items: flex-start"}
}
`;

class Layout extends Component {
  state = {
    settingsActive: false,
    beerTime: false
  };

  componentWillMount() {
    let images = localStorage.getItem("boc-data-images");
    let time = localStorage.getItem("boc-data-time");
    if (!images || images.length < 1 || !time || time.length < 1) {
      this.setState({ settingsActive: true });
    }
  }

  initBeerTime = () => {
    this.setState({ beerTime: true });
  };

  toggleActive = () => {
    if (this.state.settingsActive) {
      this.setState({
        settingsActive: !this.state.settingsActive,
        beerTime: false
      });
    } else {
      this.setState({ settingsActive: !this.state.settingsActive });
    }
  };

  render() {
    return (
      <Container>
        <Top>
          {!this.state.settingsActive ? (
            <>
              <Slider beerTime={this.state.beerTime} />
              <TopContent beerTime={this.state.beerTime}>
                <TopTitle
                  beerTime={this.state.beerTime}
                  pose={this.state.beerTime ? "center" : "left"}
                >
                  {this.state.beerTime ? (
                    <>
                      IT'S <br />{" "}
                    </>
                  ) : null}
                  BEER
                  <br /> O'CLOCK
                </TopTitle>

                <SubHeadingContainer
                  beerTime={this.state.beerTime}
                  pose={this.state.beerTime ? "center" : "left"}
                >
                  <img
                    src="./assets/SVG/logo-white.svg"
                    height="40px"
                    alt="logo"
                  />
                  &nbsp;
                  <TopSubTitle>
                    wishes {this.props.name ? this.props.name : "you"} a happy
                    beer o'clock
                  </TopSubTitle>
                </SubHeadingContainer>
                {!this.state.settingsActive && !this.state.beerTime ? (
                  <FlipClock
                    time={localStorage.getItem("boc-data-time")}
                    callback={this.initBeerTime}
                  />
                ) : null}
              </TopContent>
            </>
          ) : null}
        </Top>
        <PoseGroup>
          <Bottom key="1" active={this.state.settingsActive}>
            {!this.state.settingsActive ? (
              <SettingsButton onClick={this.toggleActive} />
            ) : null}
            <BottomTitle active={this.state.settingsActive}>
              {!this.state.beerTime
                ? "How long until the most important time of the day?"
                : "Always be awesome, drink responsibly"}
            </BottomTitle>
            {this.state.settingsActive ? (
              <Settings toggleActive={this.toggleActive} />
            ) : null}
          </Bottom>
        </PoseGroup>
      </Container>
    );
  }
}

export default Layout;
