import React, { Component } from "react";
import styled from "styled-components";
import posed, { PoseGroup } from "react-pose";

const containerPosedProps = {
  enter: { opacity: 1,  delay: 900 },
  exit: { opacity: 0,   delay: 900 }
};

const Container = styled(posed.div(containerPosedProps))`
  height: 100%;
  width: 100%;
  background-image: linear-gradient(#00000057, #00000057),url("./assets/backgrounds/${props =>
    props.activeImg}.jpg");
  position: absolute;
  background-size: cover;
  background-position-y: bottom;
  background-position: center;
  top: 0;
  left: 0;
`;

class Slider extends Component {
  state = {
    activeImgIdx: 0,
    images: []
  };


  componentWillMount(){
    let images = JSON.parse(localStorage.getItem('boc-data-images'));
    this.setState({images: images})
    this.intervalId = setInterval(() => { 
      this.setState({activeImgIdx: (this.state.activeImgIdx + 1) % this.state.images.length})
    }, 600000)
  }

  componentWillUnmount(){
    clearInterval(this.intervalId);
  }


  render() {
    return (
      <PoseGroup>
        <Container
          activeImg={this.state.images[this.state.activeImgIdx]}
          key={this.state.activeImgIdx}
        />
      </PoseGroup>
    );
  }
}

export default Slider;
