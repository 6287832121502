import React, { Component } from "react";
import styled from "styled-components";
import posed, { PoseGroup } from "react-pose";

const containerProps = {
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.9 },
  active: { scale: 0.95 },
  inactive: { scale: 1 },

};

const lgBreak = '970px';
const smBreak = '570px';
const xsmBreak = '400px';

const Container = styled(posed.div(containerProps))`
    background-image: url('/assets/backgrounds/${props => props.imgNo}.jpg');
    background-size: cover;
    width: 300px;
    height: 200px;
    cursor: pointer;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${lgBreak}){
      width: 200px;
      height: 125px;
    }

    @media (max-width: ${smBreak}){
      width: 125px;
      height: 75px;
    }

    @media (max-width: ${xsmBreak}){
      width: 100px;
      height: 75px;
    }

    ${props =>
      props.pose == "active" ? "box-shadow: 0px 3px 7px 3px #0000005c;" : null}

      img {
          width: 50%;
          user-select: none;
      }
`;

class ImageItem extends Component {
  state = {
    isActive: false
  };

  componentWillMount(){
    this.setState({isActive: this.props.active})
  }

  toggleActive = () => {
    this.setState({ isActive: !this.state.isActive });
    this.props.toggleSelectedImage(this.props.imgNo);
  };

  render() {
    return (
      <Container
        onClick={this.toggleActive}
        imgNo={this.props.imgNo}
        pose={this.state.isActive ? "active" : "inactive"}
      >
        {this.state.isActive ? (
          <img src="./assets/tick-inside-circle.png" />
        ) : null}
      </Container>
    );
  }
}

export default ImageItem;
