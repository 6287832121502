import React, { Component } from "react";
import "./App.scss";
import Layout from "./Layout.jsx";
import { Router } from "@reach/router";

class App extends Component {
  render() {
    return (
      <Router>
        <Layout path="/"/>
        <Layout path="/:name"/>
      </Router>
    );
  }
}

export default App;
